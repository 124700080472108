import React, { PureComponent } from 'react';
import MetaTags from 'react-meta-tags';
import { Layout } from '@eventbrite/eds-layout';
import {
    CONTENT_MAP,
    PAGE_TITLE_MAP,
    DESCRIPTION_MAP,
} from '../../constants/mdxComponents';

import './docs.scss';

export default class DocsPage extends PureComponent {
    constructor() {
        super();

        this.docsEl = React.createRef();
    }

    componentDidUpdate(prevProps) {
        const newPathname = this.props.location.pathname;
        const oldPathname = prevProps.location.pathname;

        if (newPathname !== oldPathname) {
            this.docsEl.current.scrollIntoView();
        }
    }

    render() {
        const MdxComponent = CONTENT_MAP[this.props.params.document];
        const pageTitle = PAGE_TITLE_MAP[this.props.params.document];
        const pageDesc = DESCRIPTION_MAP[this.props.params.document];

        return (
            <Layout maxWidth="large" hasHorizontalGutters={true}>
                <MetaTags>
                    <title>{pageTitle}</title>
                    <meta name="description" content={pageDesc} />
                </MetaTags>
                <div
                    className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-9-12"
                    ref={this.docsEl}
                >
                    <div className="markdown-body eds-l-pad-bot-12">
                        <MdxComponent />
                    </div>
                </div>
            </Layout>
        );
    }
}
