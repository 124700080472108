import { ROUTER_LINK, ANCHOR_LINK } from '@eventbrite/eds-nav-list-item';

const referrer = global?.window?.location?.pathname;
const loginReferrer =
    referrer === '/platform/' || referrer === '/platform'
        ? '/platform/api-keys'
        : referrer;
const logoutReferrer =
    referrer === '/platform/api-keys/' || referrer === '/platform/api-keys'
        ? '/platform'
        : referrer;

export const GLOBAL_HEADER_LOGO_INFO = {
    url: 'https://www.eventbrite.com/',
    iconColor: 'white',
};

export const GLOBAL_HEADER_QUICK_LINKS_LOGGED_IN = [
    {
        type: ROUTER_LINK,
        url: '/platform',
        content: 'Overview',
    },
    {
        type: ROUTER_LINK,
        url: '/platform/docs/introduction',
        content: 'Documentation',
    },
    {
        type: ROUTER_LINK,
        url: '/platform/api',
        content: 'API Reference',
    },
];

export const GLOBAL_HEADER_QUICK_LINKS = [
    ...GLOBAL_HEADER_QUICK_LINKS_LOGGED_IN,
    {
        url: `/login/?referrer=${loginReferrer}`,
        content: 'Sign In',
    },
];

export const USER_MENU_INFO = {
    iconColor: 'white',
    label: '',
    fallbackUrl: '/platform/',
    imageUrl: '',

    navItems: [
        {
            type: ANCHOR_LINK,
            content: 'Account Settings',
            path: '/account-settings/',
        },
        {
            type: ANCHOR_LINK,
            content: 'Create Event',
            path: '/create/',
        },
        {
            type: ANCHOR_LINK,
            content: 'API Status',
            path: 'https://www.eventbritestatus.com/',
        },
        {
            type: 'divider',
        },
        {
            type: ANCHOR_LINK,
            content: 'Log out',
            path: `/logout/?referrer=${logoutReferrer}`,
        },
    ],
};
