import React, { PureComponent } from 'react';

import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';

export default class HeroBannerContent extends PureComponent {
    _handleGetAFreeApiKey() {
        window.location.href = '/login/?referrer=/platform/api-keys/';
    }
    render() {
        return (
            <section className="eds-g-cell eds-g-cell-12-12 eds-g-cell-sw-7-12">
                <h1 className="eds-text-hxl">
                    Build the future of live experiences
                    <span className="eds-text-hl eds-l-pad-top-4">
                        Powered by the Eventbrite Platform
                    </span>
                </h1>

                <div className="eds-l-mar-bot-12 eds-l-mar-top-6">
                    <Button style="fill" onClick={this._handleGetAFreeApiKey}>
                        Get a Free API Key
                    </Button>
                    <Button
                        type="link"
                        style="anchor"
                        href="/platform/docs/introduction"
                        __containerClassName="learn-more-link eds-l-mar-left-4 eds-l-sn-mar-left-0 eds-l-sn-mar-top-4 eds-l-sn-block"
                    >
                        <span className="eds-l-mar-right-1">
                            Explore the docs
                        </span>
                        <Icon
                            type={<ArrowRightChunky />}
                            color="ui-orange"
                            size="xsmall"
                            __containerClassName="learn-more-icon"
                        />
                    </Button>
                </div>
            </section>
        );
    }
}
