import React, { PureComponent } from 'react';
import { GlobalFooter } from '@eventbrite/eds-global-footer';

import { GENERAL_LINKS } from '../../constants/footer';

export default class Footer extends PureComponent {
    render() {
        return <GlobalFooter generalLinks={GENERAL_LINKS} />;
    }
}
