import React, { PureComponent } from 'react';

import { CopyField } from '@eventbrite/eds-copy-field';

export default class CopyApiKeyField extends PureComponent {
    render() {
        return (
            <CopyField
                inputFieldId="api-key-field"
                label="Your private token"
                shouldFocus={true}
                inputFieldStyle={this.props.inputFieldStyle}
                value={this.props.apiKey}
            />
        );
    }
}
