import React from 'react';

import headerBgImg from '../static/images/apiKeys/crowd-success.svg';
import featureImg1 from '../static/images/apiKeys/success_search@2x.png';
import featureImg2 from '../static/images/apiKeys/success_list@2x.png';
import featureImg3 from '../static/images/apiKeys/success_embed@2x.png';
import { AlertChunky } from '@eventbrite/eds-iconography';
import { TYPE_ERROR } from '@eventbrite/eds-notification';

export const APIKEY_ERROR = {
    description:
        'Something went wrong retrieving your API key. Please try again later. You can also view and create API Keys on your account settings.',
    icon: <AlertChunky />,
    type: TYPE_ERROR,
};

export const HEROBG = {
    url: headerBgImg,
    size: '600px 243px',
};

export const FEATURELIST = [
    {
        heading: 'API Basics',
        description:
            'Understand API basics and terminology to successfully use the Eventbrite API.',
        link: '/platform/docs/api-basics',
        linkText: 'Learn the basics',
        imagePath: featureImg1,
    },

    {
        heading: 'List your Events',
        description:
            'Return a list of events you own to display on your website or app.',
        link: '/platform/docs/events',
        linkText: 'Explore events guide',
        imagePath: featureImg2,
    },
    {
        heading: 'Embed a checkout flow',
        description:
            'Quickly embed a checkout experience into your website or app.',
        link: '/platform/docs/embedded-checkout',
        linkText: 'Explore checkout guide',
        imagePath: featureImg3,
    },
];
