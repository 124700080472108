import range from 'lodash/range';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Feature from './Feature';

const NUMBER_OF_COLUMNS = 3;

const _getFeatureListItems = (features) =>
    range(NUMBER_OF_COLUMNS).map((index) => {
        const { heading, description, link, linkText, imagePath } =
            features[index];

        return (
            <div
                key={`${heading}`}
                className="eds-l-pad-vert-2 eds-l-sm-pad-vert-0 eds-l-sn-pad-vert-0 eds-l-sm-mar-bot-10 eds-l-sn-mar-bot-10 eds-l-pad-hor-3 eds-l-sm-pad-hor-0 eds-l-sn-pad-hor-0 eds-g-cell eds-g-cell-1-1 eds-g-cell-sw-1-3 eds-g-cell--has-overflow"
            >
                <Feature
                    heading={heading}
                    description={description}
                    link={link}
                    linkText={linkText}
                    imagePath={imagePath}
                />
            </div>
        );
    });

export default class ThreeColumnFeature extends PureComponent {
    static propTypes = {
        /*
        Group of features to show
        */
        features: PropTypes.arrayOf(
            PropTypes.shape({
                heading: PropTypes.string.isRequired,
                description: PropTypes.string.isRequired,
                link: PropTypes.string,
                linkText: PropTypes.string,
                imagePath: PropTypes.string,
            }),
        ),
    };

    render() {
        const { features } = this.props;

        if (!features) {
            return null;
        }

        const featureList = _getFeatureListItems(features);

        return <div className="eds-l-pad-vert-8">{featureList}</div>;
    }
}
