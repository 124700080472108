/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import MetaTags from 'react-meta-tags';

import PropTypes from 'prop-types';

import { withMainControls } from '@eventbrite/eds-structure';

import { CopyField } from '@eventbrite/eds-copy-field';
import { InputField } from '@eventbrite/eds-input-field';
import { Button } from '@eventbrite/eds-button';
import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';
import HeroBanner from '../../components/heroBanner/HeroBanner';
import HeroBannerContent from './HeroContent';
import FooterPromo from '../../components/footer/FooterPromo';
import ThreeColumnFeature from '../../components/feature/ThreeColumnFeature';
import { APIKEY_ERROR, HEROBG, FEATURELIST } from '../../constants/apiKeys';

import { fetchApplications } from '../../api/apiKey';

import './apiKeys.scss';

class IndexScreen extends PureComponent {
    static propTypes = {
        addMainNotification: PropTypes.func,
        hideMainNotification: PropTypes.func,
        setApiKey: PropTypes.func,
    };

    componentDidMount() {
        // Add a dark "theme" for content
        document.body.classList.add('theme-dark');

        const userData = this.props.userData;

        userData.then((response) => {
            if (response.status === 403) {
                this.props.router.push('/platform');
                return;
            }

            fetchApplications(response).then((appResponse) => {
                if (!appResponse.token) {
                    this._handleAddNotification();
                    return;
                }
                this.props.setApiKey(appResponse.token);
            });
        });
    }

    componentWillUnmount() {
        const { hideMainNotification } = this.props;

        if (hideMainNotification) {
            hideMainNotification();
        }
        document.body.classList.remove('theme-dark');
    }

    _handleAddNotification() {
        const { addMainNotification } = this.props;

        addMainNotification({
            type: APIKEY_ERROR.type,
            children: APIKEY_ERROR.description,
            iconType: APIKEY_ERROR.icon,
            isNotificationSticky: true,
            shouldPersist: true,
            hasCloseButton: true,
        });
    }

    render() {
        const urlExample = `https://www.eventbriteapi.com/v3/users/me/?token=${this.props.apiKey}`;
        const httpExample = `GET /v3/users/me HTTP 1.1 \nHost: www.eventbriteapi.com \nAuthorization: Bearer ${this.props.apiKey}`;

        return (
            <div>
                <MetaTags>
                    <title>Welcome Aboard! | Eventbrite Platform</title>
                </MetaTags>
                <HeroBanner bgImgPath={HEROBG.url} bgSize={HEROBG.size}>
                    <HeroBannerContent userData={this.props.userData} />
                </HeroBanner>

                <div className="marketing-container eds-l-pad-hor-10 eds-l-sn-pad-hor-5 eds-l-sm-pad-hor-5 eds-l-sw-pad-hor-5 eds-l-pad-top-12 eds-l-pad-bot-12 eds-l-sm-pad-bot-0 eds-l-sn-pad-bot-0">
                    <div className="eds-g-group">
                        <div className="eds-g-cell eds-g-cell-12-12">
                            <h2 className="eds-text-hm eds-l-mar-bot-2">
                                1. First, authenticate your API key
                            </h2>
                            <p className="eds-text-bl">
                                Get authenticated and begin making API requests.
                            </p>
                            <p className="eds-l-mar-bot-10 eds-l-mar-top-1">
                                <Button
                                    type="link"
                                    style="anchor"
                                    href="/platform/docs/authentication"
                                    __containerClassName="learn-more-link"
                                >
                                    <span className="eds-l-mar-right-1">
                                        Learn more about authentication
                                    </span>
                                    <Icon
                                        type={<ArrowRightChunky />}
                                        color="ui-orange"
                                        size="xsmall"
                                        __containerClassName="learn-more-icon"
                                    />
                                </Button>
                            </p>

                            <p className="eds-text-bl eds-l-mar-bot-6">
                                There are two ways to make an authenticated
                                request.
                            </p>
                            <ul className="eds-text-bm">
                                <li className="eds-l-mar-bot-8">
                                    &bull; URL Example
                                    <div className="eds-g-cell-11-12 eds-g-cell-mn-6-12 eds-l-mar-top-4">
                                        <CopyField
                                            inputFieldId="api-key-field"
                                            label="Your private token"
                                            shouldFocus={true}
                                            inputFieldStyle="basic"
                                            value={urlExample}
                                        />
                                    </div>
                                </li>
                                <li>
                                    &bull; HTTP Example
                                    <div className="eds-g-cell-11-12 eds-g-cell-mn-6-12 eds-g-cell-md-4-12 eds-l-mar-top-4 index-screen__http-example">
                                        <InputField
                                            id="httpExample"
                                            label="httpExample"
                                            name="httpExample"
                                            data-spec="httpExample"
                                            style="basic"
                                            isMultiline={true}
                                            rows={3}
                                            disabled={true}
                                            value={httpExample}
                                        />
                                    </div>
                                </li>
                            </ul>

                            <h2 className="eds-text-hm eds-l-mar-bot-2 eds-l-mar-top-14">
                                2. Then, continue to build with Eventbrite
                            </h2>
                            <p className="eds-text-bl">
                                List public events, promote your events, or add
                                a checkout form to your website.
                            </p>
                        </div>
                    </div>
                    <div className="eds-g-group">
                        <div className="eds-g-cell eds-g-cell-12-12 eds-g-cell--has-overflow">
                            <ThreeColumnFeature features={FEATURELIST} />
                        </div>
                    </div>
                </div>

                <FooterPromo
                    heading="Empower"
                    subHeading="By learning all the capabilities"
                    btnText="Explore the docs"
                    btnLink="/platform/docs"
                />
            </div>
        );
    }
}

export default withMainControls(IndexScreen);
