import { combineReducers } from 'redux';

import { DEFAULT_CURRENCY_FORMAT } from '@eventbrite/intl';

import { SET_IS_LOADING } from '../actions/page';

const isLoading = (state = false, { type, payload }) => {
    let nextState = state;

    if (type === SET_IS_LOADING) {
        nextState = payload;
    }

    return nextState;
};

export default combineReducers({
    isLoading,
    currencyFormat: (state = DEFAULT_CURRENCY_FORMAT) => state,
    userData: (state = {}) => state,
});
