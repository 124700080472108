import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router';
import { Icon } from '@eventbrite/eds-icon';
import { ArrowRightChunky } from '@eventbrite/eds-iconography';

import './nextSteps.scss';

export default class NextSteps extends PureComponent {
    static propTypes = {
        steps: PropTypes.arrayOf(
            PropTypes.shape({
                title: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
                linkText: PropTypes.string,
                description: PropTypes.string,
            }),
        ),
    };

    render() {
        const { steps } = this.props;

        if (!steps) {
            return null;
        }

        const stepsCard = steps.map((step) => {
            let desc;

            let linkText = 'Continue';

            if (step.description) {
                desc = <p className="eds-l-mar-bot-2">{step.description}</p>;
            }

            if (step.linkText) {
                linkText = step.linkText;
            }

            return (
                <div className="next-steps__card" key={step.title}>
                    <h3 className="eds-l-pad-bot-2">{step.title}</h3>
                    {desc}
                    <p className="learn-more-link">
                        <Link to={step.link}>
                            {linkText}
                            <Icon
                                type={<ArrowRightChunky />}
                                size="xsmall"
                                __containerClassName="learn-more-icon"
                            />
                        </Link>
                    </p>
                </div>
            );
        });

        return (
            <div className="next-steps">
                <hr className="eds-divider__hr" />
                <div className="eds-l-mar-top-8">
                    <h1 className="eds-text-hm next-steps__header">
                        Next steps
                    </h1>
                    <p className="eds-l-mar-top-2 eds-l-mar-bot-6">
                        These are the next articles to continue with your
                        building
                    </p>
                    <div className="next-steps__card-container">
                        {stepsCard}
                    </div>
                </div>
            </div>
        );
    }
}
