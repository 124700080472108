/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { flowRight } from 'lodash';

import { Structure } from '@eventbrite/eds-structure';
import { addOverlayControls } from '@eventbrite/eds-structure';
import { addFocusDrawerControls } from '@eventbrite/eds-structure';
import { addMainControls } from '@eventbrite/eds-structure';
import {
    OVERLAY_OPTIONS_PROP_TYPES,
    FOCUS_DRAWER_OPTIONS_PROP_TYPES,
    BOTTOM_BAR_OPTIONS_PROP_TYPE,
} from '@eventbrite/eds-structure';
import { NOTIFICATION_OPTIONS_PROP_TYPES } from '@eventbrite/eds-notification';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { getPrimaryNavigationList, getNavInfo } from '../utils/nav';
import { getScrollInfo } from '../utils/utils';

import '../static/fonts/settings';
import '../static/fonts/embed';

class BasePage extends PureComponent {
    static propTypes = {
        /** Router props */
        children: PropTypes.node.isRequired,
        /** Router props */

        /** HOC props */
        overlayOptions: OVERLAY_OPTIONS_PROP_TYPES,
        focusDrawerOptions: FOCUS_DRAWER_OPTIONS_PROP_TYPES,
        mainBottomBarOptions: BOTTOM_BAR_OPTIONS_PROP_TYPE,
        notificationOptions: NOTIFICATION_OPTIONS_PROP_TYPES,
        /** HOC props */

        isLoading: PropTypes.bool,
    };

    constructor(props) {
        super(props);

        const {
            location: { pathname },
        } = props;

        this.state = {
            primaryNavigationList: getPrimaryNavigationList(pathname),
            hasIndScroll: getScrollInfo(pathname),
        };
    }

    componentDidUpdate(prevProps) {
        const {
            location: { pathname },
        } = this.props;
        const {
            location: { pathname: prevPathName },
        } = prevProps;

        if (pathname === prevPathName) {
            return;
        }

        this.setState({
            primaryNavigationList: getPrimaryNavigationList(pathname),
        });
        this.setState({ hasIndScroll: getScrollInfo(pathname) });
    }

    render() {
        const {
            children,
            location: { pathname },
            overlayOptions,
            focusDrawerOptions,
            notificationOptions,
            mainBottomBarOptions,
            isLoading,
            userData,
        } = this.props;
        const { primaryNavigationList } = this.state;
        const { hasIndScroll } = this.state;
        const docsPath = '/platform/docs/';
        const header = <Header userData={userData} />;
        const footer = <Footer />;
        const { navItems } = getNavInfo(
            primaryNavigationList,
            pathname,
            docsPath,
        );

        return (
            <Structure
                header={header}
                footer={footer}
                navItems={navItems}
                isLoading={isLoading}
                hasIndependentScrolling={hasIndScroll}
                useSideDrawerAltTheme={true}
                overlayOptions={overlayOptions}
                focusDrawerOptions={focusDrawerOptions}
                notificationOptions={notificationOptions}
                mainBottomBarOptions={mainBottomBarOptions}
                fullScreenMain={true}
            >
                {children}
            </Structure>
        );
    }
}

const enhancePage = flowRight(
    addOverlayControls,
    addMainControls,
    addFocusDrawerControls,
);

export default enhancePage(BasePage);
