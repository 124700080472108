import React, { PureComponent } from 'react';

import { GlobalHeader, STYLE_ORGANIZER } from '@eventbrite/eds-global-header';
import {
    // BASE_URL,
    GLOBAL_HEADER_LOGO_INFO,
    GLOBAL_HEADER_QUICK_LINKS,
    USER_MENU_INFO,
    GLOBAL_HEADER_QUICK_LINKS_LOGGED_IN,
} from '../../constants/header';

import './header.scss';

export default class Header extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
        };
    }
    componentDidMount() {
        const userData = this.props.userData;

        userData
            .then((response) => {
                if (response.status === 403) {
                    this.setState({ isLoggedIn: false });
                } else {
                    USER_MENU_INFO.label = response.first_name || '';
                    USER_MENU_INFO.imageUrl = response.imageUrl;
                    this.setState({ isLoggedIn: true });
                }
            })
            .catch((isLoggedIn) => {
                if (!isLoggedIn) {
                    this.setState({ isLoggedIn: false });
                }
            });
    }

    render() {
        if (this.state.isLoggedIn) {
            return (
                <GlobalHeader
                    logoInfo={GLOBAL_HEADER_LOGO_INFO}
                    quickLinks={GLOBAL_HEADER_QUICK_LINKS_LOGGED_IN}
                    style={STYLE_ORGANIZER}
                    userMenuInfo={USER_MENU_INFO}
                    useNarrowBreakpoint={true}
                />
            );
        }

        return (
            <GlobalHeader
                logoInfo={GLOBAL_HEADER_LOGO_INFO}
                quickLinks={GLOBAL_HEADER_QUICK_LINKS}
                style={STYLE_ORGANIZER}
                useNarrowBreakpoint={true}
            />
        );
    }
}
