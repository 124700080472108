// eslint-disable-next-line import/unambiguous
import NeuePlakCond from './neueplak-condensed';
import { HAS_WINDOW } from '@eventbrite/feature-detection';

const settings = () => {
    if (HAS_WINDOW) {
        window.EBFONTCOND_PROPERTIES = {
            family: 'Neue Plak Condensed',
            script: NeuePlakCond,
            oldVersions: ['EB.fonts.neueplak-condensed.12-01-2018'],
            version: 'EB.fonts.neueplak-condensed.12-03-2018',
        };

        window.EBFONT_PROPERTIES = {
            family: 'Neue Plak',
            src: 'https://cdn.evbstatic.com/s3-build/perm_001/2f3525/django/js/src/eb/fonts/neueplak.js',
            oldVersions: [
                'EB.fonts.neueplak.09-20-2018',
                'EB.fonts.neueplak.09-04-2018',
                'EB.fonts.neueplak.08-30-2018',
                'EB.fonts.neueplak.08-24-2018',
                'EB.fonts.benton.10-26-2016',
                'EB.fonts.benton.11-29-2016',
            ],
            version: 'EB.fonts.neueplak.10-24-2018',
        };
    }
};

settings();
